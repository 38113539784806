import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {BooleanInput} from "@angular/cdk/coercion";
import {first, Subject} from "rxjs";
import {IUser} from "@core/services/requests/user/user.interface";
import {AuthService} from "@auth/auth.service";

@Component({
  selector: "user",
  templateUrl: "./user.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "user",
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar = true;
  user: IUser;

  private destroy$ = new Subject();

  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    const token = localStorage.getItem('accessToken');
    this.user = this.decodeToken(token);
    // console.log(this.user);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  /**
   * Sign out
   */
  public signOut(): void {
    this._authService
      .signOut()
      .pipe(first())
      .subscribe(() => location.reload());
  }

  public decodeToken(token: string): any {
    if (!token) {
      return null;
    }

    const payload = token.split('.')[1]; // The payload is the second part of the token
    try {
      return JSON.parse(atob(payload));
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  }
}
