import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, Observable, ReplaySubject, tap } from "rxjs";

import { IUser } from "@core/services/requests/user/user.interface";

//TODO. Remove after getting actual user data from BE
const MOCKUserData: any = {
  avatar: "assets/images/avatars/brian-hughes.jpg",
  email: "hughes.brian@company.com",
  id: "cfaad35d-07a3-4447-a6c3-d8c3d54fd5df",
  name: "Brian Hughes",
  status: "online",
};

@Injectable({
  providedIn: "root",
})
export class UserService {
  private _user: ReplaySubject<IUser> = new ReplaySubject<IUser>(1);

  public constructor(private _httpClient: HttpClient) {}

  public get user$(): Observable<IUser> {
    return this._user.asObservable();
  }

  public set user(user: any) {
    // TODO. Replace from 'MOCKUserData' to 'user' after getting actual user data about him from BE side.
    this._user.next(MOCKUserData);
  }

  public get(): Observable<IUser> {
    return this._httpClient.get<any>("api/common/user").pipe(
      tap((user: IUser): void => {
        this._user.next(user);
      })
    );
  }

  public update(user: IUser): Observable<unknown> {
    return this._httpClient.patch<IUser>("api/common/user", { user }).pipe(
      map((user: IUser): void => {
        this._user.next(user);
      })
    );
  }
}
