import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  switchMap,
  throwError,
} from "rxjs";
import { UserService } from "@core/services/requests/user/user.service";
import { IS_SIGNED_OUT_BECAUSE_INACTIVE_KEY } from "@shared/constants/storage-keys";
import { Router } from "@angular/router";
import { IRequest } from "@core/interfaces/request.interface";
import { IError, IResponse } from "@core/interfaces/response.interface";
import { ResponseStatusesEnum } from "@core/enums/response-statuses.enum";
import { error } from "@angular/compiler-cli/src/transformers/util";

export interface ITokenInternalResponse {
  token: string;
  expiresIn: number;
  refreshExpiresIn: number;
  refreshToken: string;
  tokenType: string;
  identified: boolean;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _authenticated = false;
  private isAuth: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _router: Router
  ) {}

  public get accessToken(): string {
    return localStorage.getItem("accessToken") ?? "";
  }

  public set accessToken(token: string) {
    localStorage.setItem("accessToken", token);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get refreshToken(): string {
    return localStorage.getItem("refreshToken") ?? "";
  }

  public set refreshToken(token: string) {
    localStorage.setItem("refreshToken", token);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get isSignedOutBecauseInactive(): string {
    return localStorage.getItem(IS_SIGNED_OUT_BECAUSE_INACTIVE_KEY) ?? "";
  }

  public setIsSignedOutBecauseInactive(flag: string): void {
    localStorage.setItem(IS_SIGNED_OUT_BECAUSE_INACTIVE_KEY, flag);
  }

  public getIsAuthenticated(): Observable<boolean> {
    return this.isAuth.asObservable();
  }

  public setIsAuth(authStatus: boolean): void {
    this.isAuth.next(authStatus);
  }

  public refreshAccessToken(): Observable<ITokenInternalResponse> {
    return this._httpClient
      .post(`api/auth/staff/refresh-token`, {
        data: {
          refreshToken: this.refreshToken,
        },
      })
      .pipe(
        map((response: IResponse<ITokenInternalResponse>) => {
          return response.data;
        })
      );
  }

  public signIn(credentials: {
    username: string;
    password: string;
    loginType: string;
  }): Observable<IResponse<ITokenInternalResponse | IError>> {
    if (this._authenticated) {
      return throwError(() => "User is already logged in."); //TODO. Translate
    }

    const body: IRequest<{
      username: string;
      password: string;
      loginType: string;
    }> = {
      data: credentials,
    };

    return this._httpClient.post("api/auth/staff/token", body).pipe(
      switchMap(
        (
          response: IResponse<ITokenInternalResponse>
        ): Observable<IResponse<ITokenInternalResponse | IError>> => {
          if (response.status === ResponseStatusesEnum.Error) {
            this._authenticated = false;
            this.setIsAuth(false);

            return of(response);
          }

          this.accessToken = response?.data?.token;

          if (response?.data?.refreshToken) {
            this.refreshToken = response?.data?.refreshToken;
          }

          this._authenticated = true;
          this.setIsAuth(true);
          localStorage.removeItem(IS_SIGNED_OUT_BECAUSE_INACTIVE_KEY);

          return of(response);
        }
      )
    );
  }

  public signInUsingToken(): Observable<boolean> {
    return this._httpClient
      .post("api/auth/sign-in-with-token", {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() => of(false)),
        switchMap((response: any) => {
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }

          this._authenticated = true;
          this.setIsAuth(true);
          localStorage.removeItem(IS_SIGNED_OUT_BECAUSE_INACTIVE_KEY);

          this._userService.user = response.user;

          return of(true);
        })
      );
  }

  public signOut(): Observable<unknown> {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    sessionStorage.clear();

    this._authenticated = false;
    this.setIsAuth(false);

    void this._router.navigate(["/auth/sign-in"]);

    return of(true);
  }

  public check(): Observable<boolean> {
    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }

    return this.signInUsingToken();
  }
}
